import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { redirectToUsingMode, routeWithParamAs } from '../services/utils/route';

const CustomBackdrop = styled(Backdrop)({
    zIndex: 300,
});

interface Props {
    redirectTo: string;
}

const Index = ({ redirectTo }: Props) => {
    const router = useRouter();

    useEffect(() => {
        return routeWithParamAs(router, redirectTo);
    }, []);

    return (
        <CustomBackdrop open={true}>
            <CircularProgress />
        </CustomBackdrop>
    );
};

export default Index;

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { mode } = context.query;
    const object = { ...context.query };
    delete object.mode;
    // @ts-ignore
    const redirectTo = redirectToUsingMode(mode, { ...object });
    return {
        props: {
            redirectTo,
        },
    };
};
