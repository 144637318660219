import { NextRouter } from 'next/router';
import qs from 'qs';
import { BADGE_PAGE, LOGIN_PAGE, LOGOUT_PAGE } from './CONST';

const assetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX;
const rootAddress = process.env.NEXT_PUBLIC_ROOT_ADDRESS;

export const routeWithParamAs = (router: NextRouter, url: string) => {
    router.push(url, `${rootAddress}${assetPrefix}${url}`, {
        shallow: true,
    });
};

export const redirectToUsingMode = (mode: string | null | undefined, query: { [key: string]: string } = {}) => {
    if (!mode) return `${LOGIN_PAGE}?${qs.stringify(query)}`;

    const splits = mode.split('.');

    let page = LOGIN_PAGE;
    switch (splits[0]) {
        case LOGIN_PAGE.slice(1):
            page = LOGIN_PAGE;
            break;
        case LOGOUT_PAGE.slice(1):
            page = LOGOUT_PAGE;
            break;
        case BADGE_PAGE.slice(1):
            page = BADGE_PAGE;
            break;
        default:
            break;
    }

    return `${page}?${qs.stringify(query)}`;
};

export const getRedirectionKeyFromMCUrl = (mcUrl: string): string | null => {
    const splits = mcUrl ? mcUrl.split('/') : null;
    return splits && splits.length > 0 ? splits[splits.length - 1] : null;
};
